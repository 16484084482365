import Card from "../components/Card";
import '../style/plan.css';

const Plan = () => {
    return (
        <div className="plan">
            <div className="card-box">
                <Card />
            </div>
        </div>
    );
}

export default Plan;