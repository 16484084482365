import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import router from './Routes/routes.js';
import { RouterProvider } from 'react-router-dom';

const approuter = router;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <RouterProvider router={approuter} />
);

reportWebVitals();
