import axios from 'axios';
import { shopify_api } from '../constants';

const useShopifyAuth = () => {

    const routeChange = async (shop) => {
        try {
            const response = await axios.get(shopify_api, {
                params: { shop }
            });

            //console.log("line 12", response.data);

            if (response.status === 200) {
                window.location.href = response.data;
            } else {
                console.error('Unexpected response status:', response.status);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return routeChange;
};  

export default useShopifyAuth;