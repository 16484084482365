import '../style/dashboard.css'
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";
import img6 from "../assets/img6.png";
import img7 from "../assets/img7.png";
import img8 from "../assets/img8.png";
import img9 from "../assets/img9.png";
import img10 from "../assets/img10.png";

import { useSearchParams } from 'react-router-dom';

const Dashboard = () => {
    const [searchParams] = useSearchParams();

    const code = searchParams.get("code") || sessionStorage.getItem("code");
    if (code) {
        sessionStorage.setItem("code", code);
    }

    return (
        <div className='dashboard'>
            <div className="info-block">
                <div className="box1">
                    <div className="mini-text0">
                        <h1>How it Work's ?</h1>
                        <p>Follow the steps below to add a wishlist widget to your store.</p>
                    </div>

                    <div className="mini-text1">
                        <p><b>* Appearance :</b> You must activate the extension in order to alter any appearance settings.</p>
                        <small>
                            Change button background color, text color, border color etc. from the extension.
                        </small>
                    </div>

                    <div className="mini-text1">
                        <p><b>* Extension :</b> Go to online store/themes, and click on customize theme and enable app embeded block.</p>
                        <small>- To add wishlist button go to app block and open product page.</small> <br />
                        <small>- Add block for wishlist button and fix the button where you want.</small> <br />
                        <small>- For customizing the button design, Click on button color and text.</small> <br />
                        <small>
                            Save the theme and go to store page.
                        </small>
                    </div>

                    <div className="mini-text1">
                        <p><b>* Plan :</b> You can not alter visual features such as colours, texts etc. if plan is free. Please subscribe to basic plan to utilise all of its features.</p>
                    </div>

                    <div className="mini-text1">
                        <p><b>* Still need and help ? :</b> Feel free to contact us from help section our service is 24x7</p>
                    </div>
                </div>

                <div className="mini-text1 image">
                    <h3 style={{marginBottom : '15px'}}>Steps to use the app extension: On the customization part of the product page, you can select the app as a block and enable it.</h3>
                    <img src={img4} style={{ width: '100%' }} alt="" />
                </div>

                <div className="mini-text1 image">
                    <h3 style={{marginBottom : '15px'}}>On enabling the block wishlist icon will appear.</h3>
                    <img src={img5} style={{ width: '100%' }} alt="" />
                </div>

                <div className="mini-text1 image">
                    <h3 style={{marginBottom : '15px'}}>Go to product page and click on add to wishlist button to add your product.</h3>
                    <img src={img6} style={{ width: '100%' }} alt="" />
                </div>

                <div className="mini-text1 image">
                    <h3 style={{marginBottom : '15px'}}>Once a product is added to wishlist, you can't add that product again.</h3>
                    <img src={img7} style={{ width: '100%' }} alt="" />
                </div>

                <div className="mini-text1 image">
                    <h3 style={{marginBottom : '15px'}}>You can also select an app block separately and drag and drop anywhere in section or block, also appearance settings like button color, text color, border radius etc. can be customized here.</h3>
                    <img src={img8} style={{ width: '100%' }} alt="" />
                </div>

                <div className="mini-text1 image">
                    <h3 style={{marginBottom : '15px'}}>To view products you've added click on any heart icon, it will redirect you to wishlist page where you can checkout or delete your product.   </h3><br />
                    <img src={img9} style={{ width: '100%' }} alt="" />
                </div>

                <div className="mini-text1 image">
                    <img src={img10} style={{ width: '100%' }} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;    