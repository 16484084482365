import React, { useState } from 'react';
import '../style/help.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSupportAuth from '../utils/customhooks/useSupportAuth';

const Help = () => {
    const [queryText, setQueryText] = useState('');

    const sendData = useSupportAuth();

    const callAPI = ()=> {
        sendData(queryText);
    }

    return (
        <div className="help">
            <div className="help-box">
                <div className="contact-box">
                    <h2>Contact support for Smart-Wishist App</h2> <br />
                    <p style={{ color: '#f58442' }}>by ENS Enterprises Pvt.Ltd.</p>
                    <p>ENS Enterprises will be able to address any questions or issues you have with Smart-Wishlist app.</p>
                    <p style={{ color: 'red' }}>For Quick help please fix a meeting with us by clicking button below!</p>
                </div>

                <div className="editor-box" aria-description='Write your query...'>
                    <h3>Describe what you need in detail :-</h3> <br />
                    <textarea name="query" id="editor" value={queryText} placeholder='Raise your query...' onChange={(e) => {
                        setQueryText(e.target.value);
                    }}></textarea>

                     <button id='msg-btn' onClick={callAPI}>Send Message</button>
                    <ToastContainer/> 

                </div>
            </div>
        </div >
    );
}

export default Help;