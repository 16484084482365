import { useState } from 'react';
import '../style/home.css';
import useShopifyAuth from '../utils/customhooks/useShopifyAuth';
import Loader from '../components/Loader';

const HomePage = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [shop, setShopName] = useState('');

    const routeChange = useShopifyAuth(); // custom hook

    const callAPI = () => {
        setIsLoading(true);
        routeChange(shop);
    }

    return (
        <>
            {isLoading ?
                (<Loader />)
                :
                (<div className="home">
                    <div className="form">
                        <label htmlFor="shop_name"><h2 style={{ marginBottom: '10px' }}>Enter your Shop Link</h2></label> <br />

                        <input type="text" id="shop_name" name="shop" placeholder="Your Shop URL" value={shop} onChange={(e) => {
                            setShopName(e.target.value);
                        }} />
                        <br />

                        <button type='submit' onClick={callAPI}>Submit</button>
                    </div>
                </div>
                )}
        </>
    );
}

export default HomePage;
