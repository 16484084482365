import '../style/header.css';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { get_plan_api } from '../utils/constants';

const Header = () => {
    const [plan, getPlan] = useState("");

    const code = sessionStorage.getItem("code");
    const decoded = jwtDecode(code)
    const shopName = decoded.jwttoken

    useEffect(() => {
        getPlanAPI();
    }, [])

    const getPlanAPI = async () => {
        try {
            const response = await axios.post(get_plan_api, {
                shopName: shopName,
            })

            const data = await response.data;

            //console.log("Plan Data", data);
            getPlan(data.data.planFullDetail);
        }
        catch (error) {
            //console.log(error.message);
        }
    }

    return (
        <div className="header">
            <div className="logo">
                <img src="/enslogo.png" alt="" />
            </div>

            <div className="navigation">
                <div className="current-plan">
                    <p style={{fontSize : '20px', marginRight : "20px"}}>Your current Plan is : <b style={{color : "#008060"}}>{plan.name}</b></p>
                </div>
            </div>
        </div>
    );
}

export default Header;

