import { PropagateLoader } from 'react-spinners';

const Loader = ()=> {
    return (
        <div className='loader' style={{display : 'flex', justifyContent : 'center', alignItems : 'center', height:"100vh"}}>
            <PropagateLoader color="#000000" size={14}/>
        </div>
    );   
}

export default Loader;