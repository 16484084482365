import axios from "axios";
import { toast } from 'react-toastify';
import { jwtDecode } from "jwt-decode";
import { support_mail_api } from "../constants";

const useSupportAuth = ()=> {
    const code = sessionStorage.getItem("code");
    const decoded = jwtDecode(code)
    // //console.log("line 10==>>", decoded);
    const shopName = decoded.jwttoken
    //console.log("line 12==>>", shopName)

    async function sendData(queryText) {

        try {
            const response = await axios.post(support_mail_api, 
            {
                shopName: shopName,
                message: queryText,
            });

            //console.log('Response:>>>>', response);

            if (response.status === 200) {
                toast('Message Sent .', {
                    autoClose: 500
                });  // Corrected the alert message
            } else {
                toast.error('Message not sent.');
            }
        } catch (error) {
            //console.log(error.message);
        }
    }

    return sendData;
}

export default useSupportAuth;