import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

import { Outlet } from 'react-router-dom';

const App = ()=> {
  
  return (
    <div className="app">
      <Header />
      
      <div className="content">
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
}

export default App;

