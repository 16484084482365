import { createBrowserRouter } from "react-router-dom";

import App from '../App';
import HomePage from "../Pages/HomePage";
import Dashboard from "../Pages/Dashboard";
import Plan from "../Pages/Plan";
import Help from "../Pages/Help";

const router = createBrowserRouter([
        {
            path: '',
            element: <HomePage />
        },
        {
            path: '',
            element: <App />,
            children: [
                {
                    path: 'dash',
                    element: <Dashboard />
                },

                {
                    path: 'plan',
                    element: <Plan />
                },

                {
                    path: 'help',
                    element: <Help />
                },
            ]
        }
    ]);

export default router;
