import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { get_plan_api, plan_create_api, free_plan_api } from '../utils/constants';

function Card() {
  const [plan, getPlan] = useState("");

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isActivated1, setIsActivated1] = useState(false);
  const [isActivated2, setIsActivated2] = useState(false);

  const code = sessionStorage.getItem("code");
  const decoded = jwtDecode(code);
  const shopName = decoded.jwttoken;
  // //console.log('line----->>>>>>>>', shopName);

  useEffect(() => {
    getPlanAPI();
}, [])

const getPlanAPI = async () => {
    try {
        const response = await axios.post(get_plan_api, {
            shopName: shopName,
        })

        const data = await response.data;
        getPlan(data.data.planFullDetail);
        //console.log("line 32 ==>>",data.data.planFullDetail);
      
    }
    catch (error) {
        //console.log(error.message);
    }
}

  async function planCreateAPI(planValue) {
    setIsLoading2(true);
    const data = {
      shopName: shopName,
      planValue: planValue
    }
    try {
      const response = await axios.post(plan_create_api, data);

      //console.log(response);

      if (response.status === 200) {
        window.location.href = response.data;
        setIsActivated2(true);
      }
      
      else {
        //console.log('Server Responded with error.');
      }
    }
    catch (error) {
      console.error('Error sending data:', error);
    }
  }

  async function freePlan() {
    setIsLoading1(true);
    try {
      const response = await axios.post(free_plan_api, {
        shopName: shopName
      })

      if (response.status === 200) {

        toast("Free Plan", {
          autoClose: 1000,
        });
      
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setIsActivated1(true);
      }
    }
    catch (error) {
      //console.log(error.message);
    }
  }

  return (
    <>
      <div className='card'>
        <div>
          <h2 style={{ textAlign: 'center' }}>Free</h2> <hr />
          <p>Limited Features</p>
          <p>Main Support</p>
          <p>24x7 Live Support</p>
        </div>

        <div>
          <button id='plan-btn' onClick={() => freePlan()} disabled={isLoading1 || isActivated1 || plan.name === "Free"} style={plan.name === "Free" ? {backgroundColor:"grey", cursor: 'not-allowed'} : {}}>{isLoading1 ? "Loading..." : plan.name === "Free" || isActivated1 ? "Subscribed" : "Subscribe"}</button>
          <ToastContainer />
        </div>
      </div>

      <div className='card'>
        <div>
          <h2 style={{ textAlign: 'center' }}>$4.99/month</h2> <hr />
          <p>All Features</p>
          <p>Main Support</p>
          <p>24x7 Live Support</p>
        </div>

        <div>
          <button id='plan-btn' onClick={() => planCreateAPI("2")} disabled={isLoading2 || isActivated2 || plan.name === "Basic"} style={plan.name === "Basic" ? {backgroundColor:"grey", cursor: 'not-allowed'} : {}}>{isLoading2 ? "Loading..." : plan.name === "Basic" || isActivated2 ? "Subscribed" : "Subscribe"}</button>
        </div>
      </div>
    </>
  )
}

export default Card
